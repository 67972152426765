.card {
  @apply bg-white p-6;
  padding: 2.5rem;
  border-radius: 1.5rem;
  @apply text-body;

  &__title {
    font-weight: bold;
    font-size: 1.25rem;
  }

  &__paragraph {
    line-height: 27px;
  }

  &__address {
    color: #666973;
  }

  &__body {
    &--scroll {
      max-height: 40vh;
      overflow-y: auto;
      margin: 0 -2rem;
      padding: .5rem 2rem;
    }
  }

  mat-divider {
    margin: 0 -2rem;
    @apply border-grey-2;
  }

  .detail {
    @apply text-deepsea-3;
    font-weight: bold;
    font-size: 1rem;

    &__title {
      @apply text-grey-3;
      font-weight: 400;
    }
  }

  &__stepBack {
    cursor: pointer;
    @apply text-deepsea-1;

    &:hover {
      @apply text-deepsea-3;
    }
  }
}

.login {
  .card {
    @apply p-10;
  }
}

.profile {
  .card {
      @apply p-6;
  }
}

.questionnaire {
  .card {
      @apply p-6 lg:p-10;
  }
}

