@import "variables";

/// Helper to clear inner floats
/// @author Nicolas Gallagher
/// @link http://nicolasgallagher.com/micro-clearfix-hack/ Micro Clearfix
@mixin clearfix {
  &::after {
    content: '';
    display: table;
    clear: both;
  }
}

/// Helper to size an element
/// @author Kitty Giraudel
/// @param {Length} $width
/// @param {Length} $height
@mixin size($width, $height: $width) {
  width: $width;
  height: $height;
}

@mixin hideScrollbar() {
  &::-webkit-scrollbar {
    display: none;
  }

  -ms-overflow-style: none;  /* IE and Edge */
  scrollbar-width: none;  /* Firefox */
}

@mixin hideCheckbox() {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/**
 * Truncate text with ellipsis (...)
 * Parent needs to have a defined width
 */
@mixin truncateText() {
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: pre;
}

@mixin truncateVertical($amount-of-lines: 2) {
  display: -webkit-box;
  max-width: 90%;
  -webkit-line-clamp: $amount-of-lines;
  -webkit-box-orient: vertical;
  overflow: hidden;
}
