.form {
  &__label {
    @apply text-body;
  }
}

.formField {
  .mdc-text-field--outlined {
    --mdc-outlined-text-field-container-shape: .75rem;
    --mdc-outlined-text-field-outline-color: #B6BAC9;
    --mdc-outlined-text-field-hover-outline-color: #005CE5;
    --mdc-outlined-text-field-focus-outline-color: #1E3999;
  }
}

.radioWrapper {
  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled+.mdc-radio__background .mdc-radio__inner-circle {
    border-color: white;
  }
  .mdc-radio__native-control:checked+.mdc-radio__background .mdc-radio__inner-circle {
    transform: scale(.4);
  }

  .mat-mdc-radio-button .mdc-radio .mdc-radio__native-control:enabled:checked+.mdc-radio__background .mdc-radio__outer-circle {
    border-width: 7px;
  }

  .mdc-radio {
    &__background {
      //width: 24px;
      //height: 24px;
    }


  }
}
