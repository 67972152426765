@import "../abstracts/mixins";
@import "../abstracts/variables";

.tableWrapper {
  overflow-y: auto;
  background-color: #FFFFFF;
  height: 85%;
}

.dataTable {
  border-radius: .5rem .5rem 0 0;
  //overflow: hidden !important; // Otherwise the border-radius isn't applied correctly
  overflow-y: hidden;

  &__header, &__cell {
    &:first-of-type {
      padding-left: 10px;
    }
  }

  &__header {
    font-size: .75rem;
    color: clr(grey, 100);
    font-weight: 500;
    //background-color: clr(blue, 10);

    .wrap {
      fa-icon {
        line-height: 0;
      }
    }

    &:hover {
      .defaultSortIcon {
        opacity: 1;
      }
    }
  }

  .directionalIcon, .defaultSortIcon {
    //color: clr(blue, 60);
  }

  .defaultSortIcon {
    opacity: 0;
    transition: opacity .2s ease-in-out;
  }

  &__cell {
    font-size: .75rem;
    font-weight: 400;

    &--refractionWrapper {
      column-gap: 2rem;
      row-gap: .25rem;
      flex-wrap: wrap;
      padding: .5rem 0;
    }

    &--refractions {
      gap: .25rem;
      max-width: 241px;
      width: 100%;
    }

    &--refraction {
      border-radius: 1rem;
      color: #1A7C41;
      font-weight: 500;
      padding: .125rem .375rem;
      outline: 1px solid #1A7C41;
      background-color: #E4F9EC;
    }

    &--blue {
      background: #C6EBF6;
      color: #15627A;
      margin-left: auto;
      outline-color: #15627A;
    }

    &--purple {
      background: #F4CDFA;
      color: #6E137D;
      margin-left: auto;
      outline-color: #6E137D;
    }

    &--image {
      @include size(24px);
      border-radius: .25rem;
    }
  }

  &__row {
    height: 1.875rem;

    &--header {
      height: 2.125rem;
    }

    &--selected {
      font-weight: 600;
      //background-color: clr(blue, 10);

      .mat-cell {
        //border-top-color: clr(blue, 60);
        //border-bottom-color: clr(blue, 60);
      }
    }

    &:hover:not(&--header) {
      @apply bg-primary-100;
      cursor: pointer;
    }

    .button {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-left: auto;
    }
  }
}

.mat-table {
  thead {
    position: sticky;
    top: 0;
    z-index: 100;
  }
}
