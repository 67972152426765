//@import "src/styles/abstracts/variables";
//@import "src/styles/abstracts/mixins";

.mat-mdc-dialog-container .mdc-dialog__content {
  color: inherit !important;
}

.mat-mdc-dialog-container .mdc-dialog__content,
.mat-mdc-dialog-container .mdc-dialog__title {
  line-height: inherit !important;
  font-size: inherit !important;
  font-weight: inherit !important;
  letter-spacing: inherit !important;
}

.dialog {
  .mat-mdc-dialog-container {
    --mdc-dialog-container-shape: 24px;
  }

  .mdc-dialog__title::before {
    display: none !important;
  }

  .mdc-dialog {
    //font-family: $body-text;
    //min-height: 40vh;

    &__container {
      padding: 0;
      overflow: visible;
      //border-radius: $dialog-border-radius;
      font-size: 1rem;
    }

    &__title {
      padding: 1rem 2rem;
      font-family: inherit;
      border-bottom: 1px solid;
      @apply border-grey-2;
      align-items: center;
      margin: 0;
      //max-height: 2.5rem;

      .button {
        &--icon {
          width: 1.25rem;
          height: 1.5rem;
          border: none;
          font-size: 1rem !important;
        }
      }
    }

    &__content {
      padding: 1rem 2rem !important;
      margin: 0;
      max-height: 70vh;
      @screen lg {
        max-height: 80vh;
      }

      max-width: 80vw;
      min-width: 300px;
      @screen lg {
        min-width: 600px;
      }
      overflow-x: hidden;

      .form {
        min-width: 500px;
        width: 100%;

        &--order {
          width: 900px;
        }

        .mat-form-field-wrapper {
          padding-bottom: 0 !important;
        }
      }

      &--noPadding {
        padding: 0;
      }

      &--overflowVisible {
        overflow: visible;
      }
    }

    .mat-mdc-dialog-actions {
      margin-bottom: 0;
      min-height: 0;
      overflow: hidden;
      border-bottom-left-radius: .5rem;
      border-bottom-right-radius: .5rem;
    }

    &__message {
      //max-width: 18.5rem;
    }

    &__warning {
      //@include size(3.5rem);
      border-radius: 100%;
      //background: clr(status, warning);

      fa-icon {
        font-size: 2rem;
        color: #7A6A15;
      }
    }

    &__buttons {
      padding: .75rem 1.5rem;
      justify-content: flex-end;
      width: 100%;
      display: flex;
      background: #F3F4F5;
      //border-top: 1px solid clr(grey, 10);
      //border-bottom-left-radius: $dialog-border-radius;
      //border-bottom-right-radius: $dialog-border-radius;
    }

    &__actions {
      display: flex;
      width: 100%;
      border-top: 1px solid;
      @apply border-grey-2;
      padding: 1rem 2rem;
    }
  }
}
