.customMenu {
  .mat-mdc-menu-panel {
    box-shadow: 0 4px 10px 1px rgba(0, 0, 0, 0.2);
    min-height: auto !important;
    max-width: none !important;
    background-color: #FFFFFF;
    border-radius: .5rem !important;
    top: .25rem;
    overflow: hidden;

    .menuAction {
      font-weight: 500;

      &--add, &--edit {
        //color: clr(blue, 60);
      }

      &--delete {
        //color: $color-red;
      }
    }

    .mat-menu-submenu-icon {
      right: 8px !important;
    }

    .mat-mdc-menu-content:not(:empty) {
      padding: 0;
    }

    .mat-mdc-menu-item {
      //font-family: $body-text;
      font-weight: 400;
      padding: 1rem;
      display: flex;
      align-items: center;

      &:not(:last-child) {
        //border-bottom: 1px solid clr(grey, 10);
      }

      &:not(.active) {
        &:hover {
          //background-color: clr(blue, 10);
        }
      }

      &:disabled {
        cursor: not-allowed;
      }
    }

    .active {
      //background-color: $button-primary-color !important;
      color: #FFFFFF;
    }
  }
}

//.customMenuBackdrop {
//  background: rgba(0, 10, 65, .4);
//  opacity: 1;
//}
