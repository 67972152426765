// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
@tailwind base;
@tailwind components;
@tailwind utilities;

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

@font-face {
  font-family: 'F37Ginger';
  src: url('../assets/fonts/F37Ginger-Regular.woff2') format('woff2'),
  url('../assets/fonts/F37Ginger-Regular.woff') format('woff');
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: 'F37Ginger';
  src: url('../assets/fonts/F37Ginger-Bold.woff2') format('woff2'),
  url('../assets/fonts/F37Ginger-Bold.woff') format('woff');
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: 'F37Ginger';
  src: url('../assets/fonts/F37Ginger-Light.woff2') format('woff2'),
  url('../assets/fonts/F37Ginger-Light.woff') format('woff');
  font-weight: 300;
  font-style: normal;
}

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$grey-palette: (
  50: #FAFAFA,
  100: #F5F5F5,
  200: #EEEEEE,
  300: #E0E0E0,
  400: #BDBDBD,
  500: #414142,
  600: #616161,
  700: #424242,
  800: #212121,
  900: #212121,
  contrast: (
    50: rgba(0, 0, 0, 0.87),
    100: rgba(0, 0, 0, 0.87),
    200: rgba(0, 0, 0, 0.87),
    300: rgba(0, 0, 0, 0.87),
    400: rgba(0, 0, 0, 0.87),
    500: white,
    600: white,
    700: white,
    800: white,
    900: white,
  )
);

$blue-palette: (
  50: #E3F2FD,
  100: #BBDEFB,
  200: #90CAF9,
  300: #64B5F6,
  400: #42A5F5,
  500: #005CE5,
  600: #1E3999,
  700: #00114D,
  800: #00114D,
  900: #00114D,
  contrast: (
    50: rgba(0, 0, 0, 0.87),
    100: rgba(0, 0, 0, 0.87),
    200: rgba(0, 0, 0, 0.87),
    300: rgba(0, 0, 0, 0.87),
    400: rgba(0, 0, 0, 0.87),
    500: rgba(0, 0, 0, 0.87),
  )
);

$web-frontend-primary: mat.define-palette($grey-palette);
$web-frontend-accent: mat.define-palette($blue-palette);

// The warn palette is optional (defaults to red).
$web-frontend-warn: mat.define-palette(mat.$red-palette);

$custom-typography: mat.define-typography-config(
  $font-family: 'F37Ginger, sans-serif',
  $headline-1: mat.define-typography-level(32px, 48px, 700),
  $body-1: mat.define-typography-level(14px, 24px, 500),
  $button: mat.define-typography-level(14px, 24px, 500),
  $subtitle-1: mat.define-typography-level(12px, 24px, 500),
);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$web-frontend-theme: mat.define-light-theme((
  color: (
    primary: $web-frontend-primary,
    accent: $web-frontend-accent,
    warn: $web-frontend-warn,
  ),
  density: -3,
  typography: $custom-typography
));
@include mat.all-component-themes($web-frontend-theme);

html, body { height: 100%; }
body {
  margin: 0;
  @apply bg-salmon-100;
}

@media print
{
  .no-print, .no-print *
  {
    display: none !important;
  }
}

@import "base/base-dir";
