//@import "global";
//@import "typography";
//@import "../abstracts/variables";
//@import "../abstracts/mixins";

@import "cards";
@import "dialogs";
@import "forms";
@import "menu";
@import "ng-select";
@import "table";


// TODO: Move and fix error styling
form-error {
  position: relative;
  right: 0 !important;
  bottom: 0 !important;
  display: flex;
  gap: .5rem;

  .mat-mdc-form-field-error {
    display: flex;
    flex-direction: column;
  }

  .mat-mdc-form-field-bottom-align::before {
    display: none;
  }

  fa-icon {
    align-self: center;
    margin-left: auto;
  }
}

h1, h2, h3, h4, h5, h6 {
  @apply font-bold text-deepsea-3;
}

h1 {
  // TODO: lg needs to be 40px
  @apply text-2xl leading-8 lg:text-4xl lg:leading-10;
}

h2 {
  // TODO: lg needs to be 32px
  @apply text-xl leading-6 lg:text-3xl lg:leading-8;
}

h3 {
  @apply text-lg leading-6 lg:text-2xl lg:leading-8;
}

h4 {
  @apply text-base leading-5 lg:text-xl lg:leading-6;
}
