:root {
  --topbar-height: 146px;
}

.mat-mdc-progress-spinner {
  --mdc-circular-progress-active-indicator-color: #005CE5;
}

.mat-divider {
  --mat-divider-color: #B6BAC9;
}
