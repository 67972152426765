.ng-select {
  min-width: 15rem;

  .ng-select-container {
    border: 1px solid;
    @apply rounded-xl border-grey-2;
    height: 2.75rem !important;
    min-height: 2.75rem !important;

    .ng-value-container {
      align-items: center;
      padding-top: 0 !important;
      gap: 0.25rem;
      padding-left: 1rem !important;

      .ng-value {
        background-color: transparent !important;
        margin-bottom: 0 !important;
        font-size: 14px;
      }

      .ng-placeholder {
        line-height: 1.25rem;
        font-weight: 400;
        @apply text-grey-3;
      }

      .ng-input {
        input {
          padding-top: .25rem;
          padding-left: 1rem !important;
        }
      }
    }
  }
}

.ng-select.ng-select-disabled > .ng-select-container {
  //background-color: clr(grey, 5);
  //color: clr(grey, 30);
}

.ng-select-searchable {
  .ng-arrow-wrapper {
    width: 2rem;
    //border-left: 1px solid clr(grey, 30);
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    padding-top: 3px;
  }
}

.ng-select-focused {
  .ng-select-container {
    box-shadow: 0px 0px 0px 2px rgba(65, 110, 255, 0.4);
  }
}

.ng-dropdown-panel.ng-select-bottom {
  margin-top: .5rem;
}

.ng-dropdown-panel.ng-select-top {
  margin-bottom: .5rem;
}

.ng-dropdown-panel {
  width: auto !important;
  z-index: 9999 !important;
  border-top-color: #E8E9EB !important;
  overflow: hidden;
  border-radius: .5rem !important;
  box-shadow: 0px 4px 10px 1px #23359A33;

  .ng-dropdown-panel-items {
    max-height: 25vh;

    .ng-optgroup {
      font-weight: 500;
      padding: .25rem 1rem;
      //background-color: clr(blue, 5);

      .checkmark {
        fa-icon {
          font-size: 1.25rem;
        }
      }
    }

    .ng-option {
      font-size: .75rem;
      font-weight: 400;
      padding: 0;
      background-color: #FFFFFF;

      .checkmark {
        fa-icon {
          font-size: .875rem;
        }
      }

      &:hover {
        //background-color: clr(blue, 5);
      }
    }

    .ng-option-child {
      padding: .25rem 2.5rem;
    }

    .ng-option-disabled {
      min-height: 2rem;
      display: flex;
      align-items: center;
      padding: 0.25rem 1rem;
    }

    .optionWrapper {
      border-bottom: 1px solid #E8E9EB;
      min-height: 2rem;
      display: flex;
      align-items: center;
      padding: 0.25rem 1rem;
      color: #121726;

      &--noBorder {
        border-bottom: none;
      }
    }

    .ng-optgroup, .ng-option {
      gap: 0.5rem;

      input {
        //@include hideCheckbox();
      }

      .checkBoxContainer {
        align-items: center;
        cursor: pointer;

        .checkmark {
          fa-icon {
            //color: clr(grey, 30);
          }
        }
      }
    }

    .ng-option-selected {
      //background-color: clr(blue, 5);

      .checkBoxContainer {
        .checkmark {
          fa-icon {
            //color: clr(blue, 60);
          }
        }
      }
    }
  }
}
